import React from 'react';
import ReactDOM from 'react-dom';
import {defineMessages, injectIntl} from 'react-intl';
import {connect} from 'react-redux';

import {
    hideNotification,
    notificationActions,
} from 'better_consult/actions/appActions.js';
import {pushHistory} from 'better_consult/components/History.js';
import autobind from 'common/decorators/autobind.js';
import events from 'events.js';
import {getFocusableEls, trapTabKey} from 'utils/focus_helpers.js';

export const messages = defineMessages({
    disconnectMessage: {
        id: 'better_consult.Notification.disconnectMessage',
        defaultMessage: 'disconnectMessage',
    },
    errorMessage: {
        id: 'better_consult.Notification.errorMessage',
        defaultMessage: 'errorMessage',
    },
    primaryButtonText: {
        id: 'better_consult.Notification.primaryButtonText',
        defaultMessage: 'primaryButtonText',
    },
    reload: {
        id: 'better_consult.Notification.reload',
        defaultMessage: 'reload',
    },
    report: {
        id: 'better_consult.Notification.report',
        defaultMessage: 'report',
    },
});

export class Notification extends React.Component {
    static defaultProps = {
        timedNoticeDelay: 3000,
    };

    constructor(props) {
        super(props);
        this.timer = null;
        this.focusableEls = null;
        this.notificationEl = null;
    }

    componentDidUpdate(prevProps) {
        clearTimeout(this.timer);
        if (this.props.isTimedNotice) {
            this.timer = setTimeout(() => {
                this.props.hideNotification();
            }, this.props.timedNoticeDelay);
        }
        if (this.props.showNotification) {
            if (this.props.showOverlay) {
                document.body.classList.add('notification-open');
            }
            this.setAttribute('notification', 'aria-hidden', false);
            this.setAttribute('app', 'aria-hidden', !!this.props.showOverlay);
            events.listen(window, 'keydown', this.handleKey);
        } else if (prevProps.showNotification) {
            if (prevProps.showOverlay) {
                document.body.classList.remove('notification-open');
            }
            this.setAttribute('notification', 'aria-hidden', true);
            this.setAttribute('app', 'aria-hidden', false);
            events.unlisten(window, 'keydown', this.handleKey);
        }
    }

    setAttribute(el, attr, val) {
        document.getElementById(el).setAttribute(attr, val);
    }

    @autobind
    handleKey(event) {
        if (this.props.showOverlay) {
            event.stopImmediatePropagation();
        }
        switch (event.key) {
            case 'Tab':
                if (
                    !this.focusableEls ||
                    !this.focusableEls.includes(document.activeElement) ||
                    this.focusableEls.some(
                        (el) => !this.notificationEl.contains(el),
                    )
                ) {
                    this.focusableEls = getFocusableEls('notification');
                }
                trapTabKey(this.focusableEls, event);
                break;
            case 'Esc': // Edge specific value
            case 'Escape':
                // hide notification when pressing ESC key and is not a timed notice
                if (!this.props.isTimedNotice) {
                    this.props.hideNotification();
                }
                break;
        }
    }

    @autobind
    handlePrimaryButtonClick() {
        const action = notificationActions[this.props.primaryButtonAction];
        if (action) {
            this.props.handleAction(action);
        } else {
            this.props.hideNotification();
        }
    }

    @autobind
    handleSecondaryButtonClick() {
        const action = notificationActions[this.props.secondaryButtonAction];
        if (action) {
            this.props.handleAction(action);
        } else {
            this.props.hideNotification();
            if (this.props.urlPath) {
                pushHistory(this.props.urlPath);
            }
        }
    }

    renderPrimaryButtonText() {
        const intl = this.props.intl;
        if (this.props.primaryButtonText) {
            return this.props.primaryButtonText;
        } else {
            return intl.formatMessage(messages.primaryButtonText);
        }
    }

    renderNotification() {
        let titleClassList = 'title';
        if (this.props.type) {
            titleClassList += ` ${this.props.type}`;
        }
        return (
            <div className="dialog-body">
                <p
                    className={titleClassList}
                    dangerouslySetInnerHTML={{
                        __html: this.props.title,
                    }}
                    data-test-id="notification-message"
                ></p>
                {!this.props.isTimedNotice && (
                    <div className="cta-group">
                        {this.props.secondaryButtonText && (
                            <button
                                className="cta cta-secondary"
                                data-test-id="notification-secondary-button"
                                onClick={this.handleSecondaryButtonClick}
                                type="button"
                            >
                                {this.props.secondaryButtonText}
                            </button>
                        )}
                        <button
                            autoFocus={true}
                            className="cta"
                            data-test-id="notification-primary-button"
                            onClick={this.handlePrimaryButtonClick}
                            type="button"
                        >
                            {this.renderPrimaryButtonText()}
                        </button>
                    </div>
                )}
            </div>
        );
    }

    render() {
        if (this.props.showNotification) {
            this.notificationEl = document.getElementById('notification');
            return ReactDOM.createPortal(
                this.renderNotification(),
                this.notificationEl,
            );
        } else {
            return null;
        }
    }
}

function mapStateToProps(store) {
    const notification = store.app.notification;
    const showNotification =
        notification.title && notification.title.length > 0;
    return {
        isTimedNotice: notification.isTimedNotice,
        primaryButtonAction: notification.primaryButton.action,
        primaryButtonText: notification.primaryButton.text,
        secondaryButtonAction: notification.secondaryButton.action,
        secondaryButtonText: notification.secondaryButton.text,
        showNotification,
        showOverlay: notification.showOverlay,
        title: notification.title,
        timedNoticeDelay: notification.timedNoticeDelay,
        type: notification.type,
        urlPath: notification.secondaryButton.urlPath,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        hideNotification: () => dispatch(hideNotification()),
        handleAction: (action) => dispatch(action()),
    };
}

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(Notification),
);
