import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';

import autobind from 'common/decorators/autobind.js';
import Icon from 'utils/components/Icon.js';

export const messages = defineMessages({
    buttonTitle: {
        id: 'better_consult.ContentHelper.buttonTitle',
        defaultMessage: 'buttonTitle',
    },
    learnMore: {
        id: 'better_consult.ContentHelper.learnMore',
        defaultMessage: 'learnMore',
    },
});

export class ContentHelper extends React.Component {
    static propTypes = {
        helperContent: PropTypes.shape({
            content: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element,
            ]),
            contentPreview: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element,
            ]),
            label: PropTypes.string,
            title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        }).isRequired,
        openByDefault: PropTypes.bool,
    };

    static defaultProps = {
        openByDefault: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            open: props.openByDefault,
        };
        this.openBtn = React.createRef();
        this.closeBtn = React.createRef();
    }

    handleFocus() {
        const btnRef = this.state.open ? 'closeBtn' : 'openBtn';
        if (this[btnRef].current) {
            this[btnRef].current.focus();
        }
    }

    @autobind
    toggleHelper() {
        this.setState(
            (state) => ({
                open: !state.open,
            }),
            this.handleFocus,
        );
    }

    renderDismissButton() {
        return (
            <button
                className="dismiss"
                data-test-id="helper-dismiss"
                onClick={this.toggleHelper}
                ref={this.closeBtn}
                title={this.props.intl.formatMessage(messages.buttonTitle)}
                type="button"
            >
                <Icon name="IconChevron" />
            </button>
        );
    }

    renderContent(title, content, contentPreview) {
        return (
            <>
                {title &&
                    !contentPreview &&
                    (React.isValidElement(title) ? title : <h1>{title}</h1>)}
                {content &&
                    (React.isValidElement(content)
                        ? content
                        : content
                              .split('\\n')
                              .map((line, i) => <p key={i}>{line}</p>))}
            </>
        );
    }

    renderCollapsed() {
        const {
            content,
            contentPreview,
            label,
            title,
        } = this.props.helperContent;

        let renderedCollapsed;

        if (contentPreview) {
            // show the preview with a learn more button
            renderedCollapsed = (
                <div
                    className="helper helper-preview"
                    data-test-id="helper-preview"
                >
                    {/* Titles are required in the helper content preview until BC-1923 is implemented */}
                    {/* {title &&
                        (React.isValidElement(title) ? (
                            title
                        ) : (
                            <h1>{title}</h1>
                        ))} */}
                    {React.isValidElement(contentPreview)
                        ? contentPreview
                        : contentPreview
                              .split('\\n')
                              .map((line, i, {length}) => {
                                  if (length - 1 === i) {
                                      // Add Learn More button on the last line
                                      return (
                                          <p key={i}>
                                              {line}
                                              <button
                                                  className="support-link"
                                                  data-test-id="helper-learn-more"
                                                  onClick={this.toggleHelper}
                                                  ref={this.openBtn}
                                                  type="button"
                                              >
                                                  {this.props.intl.formatMessage(
                                                      messages.learnMore,
                                                  )}
                                              </button>
                                          </p>
                                      );
                                  }
                                  return <p key={i}>{line}</p>;
                              })}
                </div>
            );
        } else {
            renderedCollapsed = (
                // no preview, just show a link to expand the helper
                <button
                    className="support-link"
                    data-test-id="helper-toggle"
                    onClick={this.toggleHelper}
                    ref={this.openBtn}
                    type="button"
                >
                    <Icon name="IconInfo" />
                    {label}
                </button>
            );
        }

        return (
            <>
                {renderedCollapsed}
                {/* show full helper content when screen is wide */}
                <div
                    className="helper"
                    data-test-id="helper-open-if-wide-screen"
                >
                    {this.renderDismissButton()}
                    {this.renderContent(title, content)}
                </div>
            </>
        );
    }

    renderExpanded() {
        const {content, contentPreview, title} = this.props.helperContent;

        return (
            <>
                <div className="helper helper-open" data-test-id="helper-open">
                    {this.renderDismissButton()}
                    {this.renderContent(title, content, contentPreview)}
                </div>
            </>
        );
    }

    render() {
        if (this.state.open) {
            return this.renderExpanded();
        }
        return this.renderCollapsed();
    }
}

export default injectIntl(ContentHelper);
