import Login from 'accounts/components/Login.js';
import Logout from 'accounts/components/Logout.js';
import ResendConfirmEmail from 'accounts/components/ResendConfirmEmail.js';
import ResetPassword from 'accounts/components/ResetPassword.js';
import SetPassword from 'accounts/components/SetPassword.js';
import InactiveConsultation from 'consultations/components/InactiveConsultation.js';
import ConsultationSuccess from 'accounts/components/ConsultationSuccess.js';
import PasswordSettings from 'dashboard/components/PasswordSettings.js';

let componentsList = {
    ConsultationSuccess,
    InactiveConsultation,
    Login,
    Logout,
    PasswordSettings,
    ResendConfirmEmail,
    ResetPassword,
    SetPassword,
};

export function updateComponentsList(components) {
    componentsList = {
        ...componentsList,
        ...components,
    };
}

export default function getComponent(componentName) {
    const component = componentsList[componentName];
    if (typeof component === 'undefined') {
        throw new Error(
            `Component with name "${componentName}" does not exist`,
        );
    } else {
        return component;
    }
}
