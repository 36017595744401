/* eslint max-lines: 0 */
import {defineMessages} from 'react-intl';

import deduplicate from 'utils/deduplicate.js';

import {ZONE} from 'accounts/helpers.js';

const {zone} = document.body.dataset;

export function getClinicalSymptom(store, clinicalSymptomId) {
    return store.data.clinical.symptoms[clinicalSymptomId];
}

export function getClinicalCondition(store, clinicalConditionId) {
    return store.data.clinical.conditions[clinicalConditionId];
}

export function getConsultationProblems(store) {
    const {data} = store;
    const problems = [];
    const {consultationSymptoms, pastConditions} = data.consultations;
    const {symptoms, conditions} = data.clinical;
    const {
        consultationSymptoms: consultationSymptomIds,
        pastConditions: pastConditionsIds,
    } = data.consultations.consultation;
    for (const id of consultationSymptomIds) {
        const consultationSymptom = consultationSymptoms[id];
        const problem = {id};
        if (consultationSymptom.freeTextSymptom) {
            problem.type = 'free-text problem';
            problem.name = consultationSymptom.freeTextSymptom;
        } else {
            problem.type = 'symptom';
            const symptom = symptoms[consultationSymptom.symptomId];
            problem.name = symptom.name;
        }
        problems.push(problem);
    }
    for (const id of pastConditionsIds) {
        const pastCondition = pastConditions[id];
        if (pastCondition.isPresentingProblem) {
            const condition = conditions[pastCondition.conditionId];
            const problem = {
                id,
                type: 'condition',
                name: condition.name,
            };
            problems.push(problem);
        }
    }
    return problems;
}

export function getAnswersLinkedToBodyParts(field, symptom) {
    let symptomAnswers = [];
    if (symptom[field]) {
        symptomAnswers = symptom[field];
    }
    const bodyPartsAnswers = symptom.bodyParts.reduce((acc, bodyPart) => {
        if (!bodyPart[field]) {
            return [];
        }
        return [...acc, ...bodyPart[field]];
    }, []);
    return deduplicate(
        [...symptomAnswers, ...bodyPartsAnswers],
        (item) => item.id,
    ).map((val) => ({
        text: val.name,
        id: val.id,
    }));
}

function redFlagSort(a, b) {
    if (!a.redFlag && b.redFlag) {
        return 1;
    } else if (a.redFlag && !b.redFlag) {
        return -1;
    }
    return 0;
}

function compressRelatedSymptoms(relatedSymptoms) {
    // Takes in an object structure consisting of body system as the key
    // and the related symptoms as a value and returns a compressed structure
    // which has the most populated body systems as their own key and
    // everything else gets lumped into other
    const otherKey = 'other';
    const MIN_ANSWERS = 4;
    const compressedRelatedSymptoms = {};
    const sortedRelatedSymptoms = Object.entries(relatedSymptoms).sort(
        // eslint-disable-next-line no-unused-vars
        ([key1, val1], [key2, val2]) => val1.length - val2.length,
    );
    let other = [];

    for (const [bs, rs] of sortedRelatedSymptoms) {
        if (bs === otherKey) {
            other = other.concat(rs);
        } else {
            if (rs.length >= MIN_ANSWERS) {
                compressedRelatedSymptoms[bs] = rs;
            } else {
                other.unshift(...rs);
            }
        }
    }
    if (other.length) {
        compressedRelatedSymptoms[otherKey] = other;
    }
    for (const [bs, rs] of Object.entries(compressedRelatedSymptoms)) {
        compressedRelatedSymptoms[bs] = rs.sort(redFlagSort);
    }
    return compressedRelatedSymptoms;
}

export function getConstitutionalSymptoms(store, symptomId) {
    const clinicalSymptom = store.data.clinical.symptoms[symptomId];
    if (!clinicalSymptom.askConstitutional) {
        return null;
    }
    const relatedSymptoms =
        getClinicalSymptom(store, symptomId).relatedSymptoms || [];
    let constitutionalSymptoms = Object.values(
        store.data.clinical.constitutionalSymptoms,
    );
    // Filter out any symptoms from constitutionalSymptoms that are in
    // related symptoms.
    const {consultation, consultationSymptoms} = store.data.consultations;
    const symptomIds = consultation.consultationSymptoms.map(
        (id) => consultationSymptoms[id].symptomId,
    );
    constitutionalSymptoms = constitutionalSymptoms.filter(
        (symp) =>
            !relatedSymptoms.find((rs) => rs.id === symp.id) &&
            !symptomIds.includes(symp.id),
    );
    if (constitutionalSymptoms.length) {
        return constitutionalSymptoms.map((cs) => ({
            text: cs.name,
            id: cs.id,
        }));
    } else {
        return null;
    }
}

export function sortRelatedSymptomsByBodySystems(
    relatedSymptoms,
    isSymptom = true,
) {
    let orderedRelatedSymptoms = {};
    const populatedIds = [];
    const otherKey = 'other';
    relatedSymptoms.forEach((rs) => {
        const bodySystems = rs.bodySystems;
        if (!bodySystems.length && !populatedIds.includes(rs.id)) {
            if (!orderedRelatedSymptoms[otherKey]) {
                orderedRelatedSymptoms[otherKey] = [];
            }
            orderedRelatedSymptoms[otherKey].push({
                id: rs.id,
                text: rs.name,
                redFlag: rs.redFlag,
            });
            populatedIds.push(rs.id);
        }
        bodySystems.forEach((bs) => {
            if (!orderedRelatedSymptoms[bs.name]) {
                orderedRelatedSymptoms[bs.name] = [];
            }
            if (!populatedIds.includes(rs.id)) {
                orderedRelatedSymptoms[bs.name].push({
                    id: rs.id,
                    text: rs.name,
                    redFlag: rs.redFlag,
                });
                populatedIds.push(rs.id);
            }
        });
    });
    orderedRelatedSymptoms = compressRelatedSymptoms(orderedRelatedSymptoms);
    if (!isSymptom) {
        const otherArray = orderedRelatedSymptoms[otherKey];
        const sortedRSArray = Object.entries(orderedRelatedSymptoms).sort(
            // eslint-disable-next-line no-unused-vars
            ([key1, val1], [key2, val2]) => val2.length - val1.length,
        );

        orderedRelatedSymptoms = sortedRSArray.reduce((acc, curr) => {
            if (curr[0] !== 'other') {
                return acc.concat(curr[1]);
            }
            return acc;
        }, []);
        if (otherArray) {
            orderedRelatedSymptoms.push(...otherArray);
        }
    }
    return orderedRelatedSymptoms;
}

const messages = defineMessages({
    comesAndGoes: {
        id: 'clinical.helpers.comesAndGoes',
        defaultMessage: 'comesAndGoes',
    },
    constantAndContinuous: {
        id: 'clinical.helpers.constantAndContinuous',
        defaultMessage: 'constantAndContinuous',
    },
    oneOff: {
        id: 'clinical.helpers.oneOff',
        defaultMessage: 'oneOff',
    },
    male: {
        id: 'clinical.helpers.male',
        defaultMessage: 'male',
    },
    female: {
        id: 'clinical.helpers.female',
        defaultMessage: 'female',
    },
    other: {
        id: 'clinical.helpers.other',
        defaultMessage: 'other',
    },
    noAnswer: {
        id: 'clinical.helpers.noAnswer',
        defaultMessage: 'noAnswer',
    },
    pregnancyNotSure: {
        id: 'clinical.helpers.pregnancyNotSure',
        defaultMessage: 'notSure',
    },
    pregnant: {
        id: 'clinical.helpers.pregnant',
        defaultMessage: 'pregnant',
    },
    notPregnant: {
        id: 'clinical.helpers.notPregnant',
        defaultMessage: 'notPregnant',
    },
    leftHanded: {
        id: 'clinical.helpers.leftHanded',
        defaultMessage: 'leftHanded',
    },
    rightHanded: {
        id: 'clinical.helpers.rightHanded',
        defaultMessage: 'rightHanded',
    },
    drinkingWeekends: {
        id: 'clinical.helpers.mainlyOnWeekends',
        defaultMessage: 'mainlyOnWeekends',
    },
    drinkingEveryday: {
        id: 'clinical.helpers.everyday',
        defaultMessage: 'everyday',
    },
    drinkingMostDays: {
        id: 'clinical.helpers.onMostDays',
        defaultMessage: 'onMostDays',
    },
    drinkingSomeDays: {
        id: 'clinical.helpers.onSomeDays',
        defaultMessage: 'onSomeDays',
    },
    drinkingInBinges: {
        id: 'clinical.helpers.inBinges',
        defaultMessage: 'inBinges',
    },
    drinkingRarely: {
        id: 'clinical.helpers.rarely',
        defaultMessage: 'rarely',
    },
    drinkingNever: {
        id: 'clinical.helpers.never',
        defaultMessage: 'never',
    },
    drugsNo: {
        id: 'clinical.helpers.drugsNo',
        defaultMessage: 'drugsNo',
    },
    drugsYes: {
        id: 'clinical.helpers.drugsYes',
        defaultMessage: 'drugsYes',
    },
    drugsNotAnymore: {
        id: 'clinical.helpers.drugsNotAnymore',
        defaultMessage: 'drugsNotAnymore',
    },
    drugsRegularUse: {
        id: 'clinical.helpers.drugRegularUse',
        defaultMessage: 'drugRegularUse',
    },
    drugsEveryWeekend: {
        id: 'clinical.helpers.drugEveryWeekend',
        defaultMessage: 'drugEveryWeekend',
    },
    drugsEveryWeek: {
        id: 'clinical.helpers.drugEveryWeek',
        defaultMessage: 'drugEveryWeek',
    },
    drugsEveryday: {
        id: 'clinical.helpers.drugEveryday',
        defaultMessage: 'drugEveryday',
    },
    drugsFewTimesAYear: {
        id: 'clinical.helpers.drugFewTimesAYear',
        defaultMessage: 'drugFewTimesAYear',
    },
    drugsNotForSomeYears: {
        id: 'clinical.helpers.drugNotForSomeYears',
        defaultMessage: 'drugNotForSomeYears',
    },
    smokingQuit: {
        id: 'clinical.helpers.smokingQuit',
        defaultMessage: 'smokingQuit',
    },
    smokingNeverSmoked: {
        id: 'clinical.helpers.neverSmoked',
        defaultMessage: 'neverSmoked',
    },
    smokingCurrently: {
        id: 'clinical.helpers.currentlySmoke',
        defaultMessage: 'currentlySmoke',
    },
    smokingSocially: {
        id: 'clinical.helpers.sociallySmoke',
        defaultMessage: 'sociallySmoke',
    },
    maritalSingle: {
        id: 'clinical.helpers.maritalSingle',
        defaultMessage: 'maritalSingle',
    },
    maritalMarried: {
        id: 'clinical.helpers.maritalMarried',
        defaultMessage: 'maritalMarried',
    },
    maritalDeFacto: {
        id: 'clinical.helpers.maritalDeFacto',
        defaultMessage: 'maritalDeFacto',
    },
    maritalEngaged: {
        id: 'clinical.helpers.maritalEngaged',
        defaultMessage: 'maritalEngaged',
    },
    maritalDivorced: {
        id: 'clinical.helpers.maritalDivorced',
        defaultMessage: 'maritalDivorced',
    },
    maritalWidowed: {
        id: 'clinical.helpers.maritalWidowed',
        defaultMessage: 'maritalWidowed',
    },
    maritalSeparated: {
        id: 'clinical.helpers.maritalSeparated',
        defaultMessage: 'maritalSeparated',
    },
    employmentFullTime: {
        id: 'clinical.helpers.employmentFullTime',
        defaultMessage: 'employmentFullTime',
    },

    // This is deprecated and not displayed anymore in AU
    employmentPartTime: {
        id: 'clinical.helpers.employmentPartTime',
        defaultMessage: 'employmentPartTime',
    },
    employmentPartTimeCasual: {
        id: 'clinical.helpers.employmentPartTimeCasual',
        defaultMessage: 'employmentPartTimeCasual',
    },
    // This is deprecated and not displayed anymore in AU
    employmentUnemployed: {
        id: 'clinical.helpers.employmentUnemployed',
        defaultMessage: 'employmentUnemployed',
    },
    employmentSickLeave: {
        id: 'clinical.helpers.employmentSickLeave',
        defaultMessage: 'employmentSickLeave',
    },
    employmentParentalLeave: {
        id: 'clinical.helpers.employmentParentalLeave',
        defaultMessage: 'employmentParentalLeave',
    },
    employmentNotWorking: {
        id: 'clinical.helpers.employmentNotWorking',
        defaultMessage: 'employmentNotWorking',
    },
    employmentVolunteer: {
        id: 'clinical.helpers.employmentVolunteer',
        defaultMessage: 'employmentVolunteer',
    },
    employmentCaregiver: {
        id: 'clinical.helpers.employmentCaregiver',
        defaultMessage: 'employmentCaregiver',
    },
    employmentRetired: {
        id: 'clinical.helpers.employmentRetired',
        defaultMessage: 'employmentRetired',
    },
    employmentSchoolStudent: {
        id: 'clinical.helpers.employmentSchoolStudent',
        defaultMessage: 'employmentSchoolStudent',
    },
    employmentFullTimeStudent: {
        id: 'clinical.helpers.employmentFullTimeStudent',
        defaultMessage: 'employmentFullTimeStudent',
    },
    employmentPartTimeStudent: {
        id: 'clinical.helpers.employmentPartTimeStudent',
        defaultMessage: 'employmentPartTimeStudent',
    },
    employmentDisabilityPension: {
        id: 'clinical.helpers.employmentDisabilityPension',
        defaultMessage: 'employmentDisabilityPension',
    },
    environmentDayCare: {
        id: 'clinical.helpers.dayCare',
        defaultMessage: 'dayCare',
    },
    environmentHomeCare: {
        id: 'clinical.helpers.homeCare',
        defaultMessage: 'homeCare',
    },
    environmentPreschool: {
        id: 'clinical.helpers.preschool',
        defaultMessage: 'preschool',
    },
    environmentOther: {
        id: 'clinical.helpers.environmentOther',
        defaultMessage: 'environmentOther',
    },
    medicationRegularityAsNeeded: {
        id: 'clinical.helpers.medicationRegularityAsNeeded',
        defaultMessage: 'medicationRegularityAsNeeded',
    },
    medicationRegularityRegularly: {
        id: 'clinical.helpers.medicationRegularityRegularly',
        defaultMessage: 'medicationRegularityRegularly',
    },
    durationSeconds: {
        id: 'clinical.helpers.durationSeconds',
        defaultMessage: 'durationSeconds',
    },
    durationMinutes: {
        id: 'clinical.helpers.durationMinutes',
        defaultMessage: 'durationMinutes',
    },
    durationHours: {
        id: 'clinical.helpers.durationHours',
        defaultMessage: 'durationHours',
    },
    durationDays: {
        id: 'clinical.helpers.durationDays',
        defaultMessage: 'durationDays',
    },
    durationWeeks: {
        id: 'clinical.helpers.durationWeeks',
        defaultMessage: 'durationWeeks',
    },
    durationMonths: {
        id: 'clinical.helpers.durationMonths',
        defaultMessage: 'durationMonths',
    },
    durationYears: {
        id: 'clinical.helpers.durationYears',
        defaultMessage: 'durationYears',
    },
    timeFramePerHour: {
        id: 'clinical.helpers.timeFramePerHour',
        defaultMessage: 'timeFramePerHour',
    },
    timeFramePerDay: {
        id: 'clinical.helpers.timeFramePerDay',
        defaultMessage: 'timeFramePerDay',
    },
    timeFramePerWeek: {
        id: 'clinical.helpers.timeFramePerWeek',
        defaultMessage: 'timeFramePerWeek',
    },
    timeFramePerMonth: {
        id: 'clinical.helpers.timeFramePerMonth',
        defaultMessage: 'timeFramePerMonth',
    },
    timeFramePerYear: {
        id: 'clinical.helpers.timeFramePerYear',
        defaultMessage: 'timeFramePerYear',
    },
    wellControlled: {
        id: 'clinical.helpers.wellControlled',
        defaultMessage: 'wellControlled',
    },
    reasonablyWellControlled: {
        id: 'clinical.helpers.reasonablyWellControlled',
        defaultMessage: 'reasonablyWellControlled',
    },
    notSoWellControlled: {
        id: 'clinical.helpers.notSoWellControlled',
        defaultMessage: 'notSoWellControlled',
    },
    poorlyControlled: {
        id: 'clinical.helpers.poorlyControlled',
        defaultMessage: 'poorlyControlled',
    },
    recentChangeBetter: {
        id: 'clinical.helpers.recentChangeBetter',
        defaultMessage: 'recentChangeBetter',
    },
    recentChangeWorse: {
        id: 'clinical.helpers.recentChangeWorse',
        defaultMessage: 'recentChangeWorse',
    },
    recentChangeSame: {
        id: 'clinical.helpers.recentChangeSame',
        defaultMessage: 'recentChangeSame',
    },
    walkInExistingPatient: {
        id: 'consultations.WalkInPatientFlowStep.walkInExistingPatient',
        defaultMessage: 'walkInExistingPatient',
    },
    walkInNewPatient: {
        id: 'consultations.WalkInPatientFlowStep.walkInNewPatient',
        defaultMessage: 'walkInNewPatient',
    },
});

export const PATTERN_CONSTANT_CONTINUOUS = {
    id: 'CC',
    message: messages.constantAndContinuous,
};

export const PATTERN_COMES_GOES = {
    id: 'CG',
    message: messages.comesAndGoes,
};

export const PATTERN_ONE_OFF = {
    id: 'OO',
    message: messages.oneOff,
};

export const patternAnswers = [
    PATTERN_CONSTANT_CONTINUOUS,
    PATTERN_COMES_GOES,
    PATTERN_ONE_OFF,
];

export const SEX_MALE = {
    id: 'MALE',
    message: messages.male,
};

export const SEX_FEMALE = {
    id: 'FEMALE',
    message: messages.female,
};

export const SEX_OTHER = {
    id: 'OTHER',
    message: messages.other,
};

export const SEX_NO_ANSWER = {
    id: 'NO_ANSWER',
    message: messages.noAnswer,
};

export const sexAnswers = [SEX_MALE, SEX_FEMALE, SEX_OTHER, SEX_NO_ANSWER];

export const HAND_LEFT = {
    id: 'LEFT',
    message: messages.leftHanded,
};

export const HAND_RIGHT = {
    id: 'RIGHT',
    message: messages.rightHanded,
};

export const handednessAnswers = [HAND_LEFT, HAND_RIGHT];

export const DRINKING_WEEKENDS = {
    id: 'MOW',
    message: messages.drinkingWeekends,
};
export const DRINKING_EVERY_DAY = {
    id: 'ED',
    message: messages.drinkingEveryday,
};
export const DRINKING_ON_MOST_DAYS = {
    id: 'OMD',
    message: messages.drinkingMostDays,
};
export const DRINKING_ON_SOME_DAYS = {
    id: 'OSD',
    message: messages.drinkingSomeDays,
};
export const DRINKING_IN_BINGES = {
    id: 'INB',
    message: messages.drinkingInBinges,
};
export const DRINKING_RARELY = {
    id: 'RARE',
    message: messages.drinkingRarely,
};
export const DRINKING_NEVER = {
    id: 'NEVER',
    message: messages.drinkingNever,
};

export const drinkingFrequencyAnswers = [
    DRINKING_WEEKENDS,
    DRINKING_EVERY_DAY,
    DRINKING_ON_MOST_DAYS,
    DRINKING_ON_SOME_DAYS,
    DRINKING_IN_BINGES,
    DRINKING_RARELY,
    DRINKING_NEVER,
];

export const DRUGS_NO = {
    id: 'NO',
    message: messages.drugsNo,
};
export const DRUGS_YES = {
    id: 'YES',
    message: messages.drugsYes,
};
export const DRUGS_NOT_ANYMORE = {
    id: 'NFSYEARS',
    message: messages.drugsNotAnymore,
};

export const drugFrequencyAnswers = [DRUGS_YES, DRUGS_NOT_ANYMORE, DRUGS_NO];

export const DRUGS_REGULAR_USE = {
    id: 'DRU',
    message: messages.drugsRegularUse,
};
export const DRUGS_EVERY_WEEKEND = {
    id: 'DEWE',
    message: messages.drugsEveryWeekend,
};
export const DRUGS_EVERY_WEEK = {
    id: 'DEW',
    message: messages.drugsEveryWeek,
};
export const DRUGS_EVERY_DAY = {
    id: 'DED',
    message: messages.drugsEveryday,
};
export const DRUGS_FEW_TIMES_A_YEAR = {
    id: 'DFTY',
    message: messages.drugsFewTimesAYear,
};
export const DRUGS_NOT_FOR_SOME_YEARS = {
    id: 'DNFSY',
    message: messages.drugsNotForSomeYears,
};

export const drugUsageAnswers = [
    DRUGS_REGULAR_USE,
    DRUGS_EVERY_WEEKEND,
    DRUGS_EVERY_WEEK,
    DRUGS_EVERY_DAY,
    DRUGS_FEW_TIMES_A_YEAR,
    DRUGS_NOT_FOR_SOME_YEARS,
];

export const SMOKING_QUIT = {
    id: 'QUIT',
    message: messages.smokingQuit,
};
export const SMOKING_NEVER_SMOKED = {
    id: 'NESM',
    message: messages.smokingNeverSmoked,
};
export const SMOKING_CURRENTLY = {
    id: 'CUSM',
    message: messages.smokingCurrently,
};
export const SMOKING_SOCIALLY = {
    id: 'SOSM',
    message: messages.smokingSocially,
};

export const smokingAnswers = [
    SMOKING_CURRENTLY,
    SMOKING_SOCIALLY,
    SMOKING_QUIT,
    SMOKING_NEVER_SMOKED,
];

export const MARITAL_SINGLE = {
    id: 'SING',
    message: messages.maritalSingle,
};
export const MARITAL_MARRIED = {
    id: 'MARR',
    message: messages.maritalMarried,
};
export const MARITAL_DE_FACTO = {
    id: 'DEFA',
    message: messages.maritalDeFacto,
};
export const MARITAL_ENGAGED = {
    id: 'ENGD',
    message: messages.maritalEngaged,
};
export const MARITAL_DIVORCED = {
    id: 'DIVO',
    message: messages.maritalDivorced,
};
export const MARITAL_WIDOWED = {
    id: 'WIDOW',
    message: messages.maritalWidowed,
};
export const MARITAL_SEPARATED = {
    id: 'SEP',
    message: messages.maritalSeparated,
};

export const maritalStatusAnswers = [
    MARITAL_SINGLE,
    MARITAL_MARRIED,
    MARITAL_DE_FACTO,
    MARITAL_ENGAGED,
    MARITAL_DIVORCED,
    MARITAL_WIDOWED,
    MARITAL_SEPARATED,
];

// This is deprecated in AU
export const EMPLOYMENT_PART_TIME = {
    id: 'WPT',
    message: messages.employmentPartTime,
};
// This is deprecated in AU
export const EMPLOYMENT_UNEMPLOYED = {
    id: 'UNEM',
    message: messages.employmentUnemployed,
};

export const EMPLOYMENT_FULL_TIME = {
    id: 'WFT',
    message: messages.employmentFullTime,
};
export const EMPLOYMENT_PART_TIME_CASUAL = {
    id: 'WPTC',
    message: messages.employmentPartTimeCasual,
};
export const EMPLOYMENT_SICK_LEAVE = {
    id: 'OSL',
    message: messages.employmentSickLeave,
};
export const EMPLOYMENT_PARENTAL_LEAVE = {
    id: 'OPL',
    message: messages.employmentParentalLeave,
};
export const EMPLOYMENT_NOT_WORKING = {
    id: 'NWRK',
    message: messages.employmentNotWorking,
};
export const EMPLOYMENT_VOLUNTEER = {
    id: 'VOL',
    message: messages.employmentVolunteer,
};
export const EMPLOYMENT_CAREGIVER = {
    id: 'CARE',
    message: messages.employmentCaregiver,
};
export const EMPLOYMENT_RETIRED = {
    id: 'RET',
    message: messages.employmentRetired,
};
export const EMPLOYMENT_SCHOOL_STUDENT = {
    id: 'SSTUD',
    message: messages.employmentSchoolStudent,
};
export const EMPLOYMENT_FULL_TIME_STUDENT = {
    id: 'FTSTUD',
    message: messages.employmentFullTimeStudent,
};
export const EMPLOYMENT_PART_TIME_STUDENT = {
    id: 'PTSTUD',
    message: messages.employmentPartTimeStudent,
};
export const EMPLOYMENT_DISABILITY_PENSION = {
    id: 'DISPEN',
    message: messages.employmentDisabilityPension,
};

let employmentAnswersDraft;

const employmentAnswersAU = [
    EMPLOYMENT_FULL_TIME,
    EMPLOYMENT_PART_TIME_CASUAL,
    EMPLOYMENT_SICK_LEAVE,
    EMPLOYMENT_PARENTAL_LEAVE,
    EMPLOYMENT_NOT_WORKING,
    EMPLOYMENT_VOLUNTEER,
    EMPLOYMENT_CAREGIVER,
    EMPLOYMENT_RETIRED,
    EMPLOYMENT_SCHOOL_STUDENT,
    EMPLOYMENT_FULL_TIME_STUDENT,
    EMPLOYMENT_PART_TIME_STUDENT,
    EMPLOYMENT_DISABILITY_PENSION,
];

const employmentAnswersUS = [
    EMPLOYMENT_FULL_TIME,
    EMPLOYMENT_PART_TIME,
    EMPLOYMENT_UNEMPLOYED,
    EMPLOYMENT_RETIRED,
    EMPLOYMENT_SICK_LEAVE,
    EMPLOYMENT_PARENTAL_LEAVE,
    EMPLOYMENT_SCHOOL_STUDENT,
    EMPLOYMENT_FULL_TIME_STUDENT,
    EMPLOYMENT_PART_TIME_STUDENT,
];

// Adding zone specific employment types
if (zone === ZONE.US) {
    employmentAnswersDraft = employmentAnswersUS;
} else {
    employmentAnswersDraft = employmentAnswersAU;
}

export const employmentAnswers = employmentAnswersDraft;

export const CARE_ENVIRONMENT_DAY_CARE = {
    id: 'DAYCARE',
    message: messages.environmentDayCare,
};

export const CARE_ENVIRONMENT_HOME_CARE = {
    id: 'HOMECARE',
    message: messages.environmentHomeCare,
};

export const CARE_ENVIRONMENT_PRE_SCHOOL = {
    id: 'PRESCHOOL',
    message: messages.environmentPreschool,
};

export const CARE_ENVIRONMENT_OTHER = {
    id: 'OTHER',
    message: messages.environmentOther,
};

export const careEnvironmentAnswers = [
    CARE_ENVIRONMENT_DAY_CARE,
    CARE_ENVIRONMENT_HOME_CARE,
    CARE_ENVIRONMENT_PRE_SCHOOL,
    CARE_ENVIRONMENT_OTHER,
];

export const employedStatuses = [
    EMPLOYMENT_FULL_TIME.id,
    EMPLOYMENT_PART_TIME_CASUAL.id,
    EMPLOYMENT_SICK_LEAVE.id,
    EMPLOYMENT_PARENTAL_LEAVE.id,
    // Deprecated. Included for backwards compatibility purposes
    EMPLOYMENT_PART_TIME.id,
];

export const MEDICATION_REGULARITY_AS_NEEDED = {
    id: 'AS_NEEDED',
    message: messages.medicationRegularityAsNeeded,
};

export const MEDICATION_REGULARITY_REGULARLY = {
    id: 'REGULARLY',
    message: messages.medicationRegularityRegularly,
};

export const medicationRegularityAnswers = [
    MEDICATION_REGULARITY_AS_NEEDED,
    MEDICATION_REGULARITY_REGULARLY,
];

export const DURATION_TIME_FRAME_SECS = {
    id: 'DTFSE',
    message: messages.durationSeconds,
    unit: 'seconds',
};

export const DURATION_TIME_FRAME_MINS = {
    id: 'DTFMI',
    message: messages.durationMinutes,
    unit: 'minutes',
};

export const DURATION_TIME_FRAME_HOURS = {
    id: 'DTFH',
    message: messages.durationHours,
    unit: 'hours',
};

export const DURATION_TIME_FRAME_DAYS = {
    id: 'DTFD',
    message: messages.durationDays,
    unit: 'days',
};

export const DURATION_TIME_FRAME_WEEKS = {
    id: 'DTFW',
    message: messages.durationWeeks,
    unit: 'weeks',
};

export const DURATION_TIME_FRAME_MONTHS = {
    id: 'DTFMO',
    message: messages.durationMonths,
    unit: 'months',
};

export const DURATION_TIME_FRAME_YEARS = {
    id: 'DTFY',
    message: messages.durationYears,
    unit: 'years',
};

export const durationTimeFrameAnswers = [
    DURATION_TIME_FRAME_MINS,
    DURATION_TIME_FRAME_HOURS,
    DURATION_TIME_FRAME_DAYS,
    DURATION_TIME_FRAME_WEEKS,
    DURATION_TIME_FRAME_MONTHS,
    DURATION_TIME_FRAME_YEARS,
];

export const firstOccurrenceTimeFrameAnswers = [
    DURATION_TIME_FRAME_HOURS,
    DURATION_TIME_FRAME_DAYS,
    DURATION_TIME_FRAME_WEEKS,
    DURATION_TIME_FRAME_MONTHS,
    DURATION_TIME_FRAME_YEARS,
];

export const pastConditionDiagnosedTimeFrameAnswers = [
    DURATION_TIME_FRAME_DAYS,
    DURATION_TIME_FRAME_WEEKS,
    DURATION_TIME_FRAME_MONTHS,
    DURATION_TIME_FRAME_YEARS,
];

const durationAnswers = [
    DURATION_TIME_FRAME_HOURS,
    DURATION_TIME_FRAME_DAYS,
    DURATION_TIME_FRAME_WEEKS,
    DURATION_TIME_FRAME_MONTHS,
    DURATION_TIME_FRAME_YEARS,
];

export const patternDurationAnswers = durationAnswers;

export const demographicDurationAnswers = [
    DURATION_TIME_FRAME_DAYS,
    DURATION_TIME_FRAME_WEEKS,
    DURATION_TIME_FRAME_MONTHS,
    DURATION_TIME_FRAME_YEARS,
];

export const demographicAgeAnswers = [
    DURATION_TIME_FRAME_WEEKS,
    DURATION_TIME_FRAME_MONTHS,
    DURATION_TIME_FRAME_YEARS,
];

export const symptomEpisodeLengthAnswers = [
    DURATION_TIME_FRAME_SECS,
    DURATION_TIME_FRAME_MINS,
    DURATION_TIME_FRAME_HOURS,
    DURATION_TIME_FRAME_DAYS,
    DURATION_TIME_FRAME_WEEKS,
    DURATION_TIME_FRAME_MONTHS,
    DURATION_TIME_FRAME_YEARS,
];

export const TIME_FRAME_PER_HOUR = {
    id: 'TFPHOUR',
    message: messages.timeFramePerHour,
};
export const TIME_FRAME_PER_DAY = {
    id: 'TFPDAY',
    message: messages.timeFramePerDay,
};
export const TIME_FRAME_PER_WEEK = {
    id: 'TFPWEEK',
    message: messages.timeFramePerWeek,
};
export const TIME_FRAME_PER_MONTH = {
    id: 'TFPMONTH',
    message: messages.timeFramePerMonth,
};
export const TIME_FRAME_PER_YEAR = {
    id: 'TFPYEAR',
    message: messages.timeFramePerYear,
};

export const demographicTimeFrameAnswers = [
    TIME_FRAME_PER_DAY,
    TIME_FRAME_PER_WEEK,
    TIME_FRAME_PER_MONTH,
    TIME_FRAME_PER_YEAR,
];

export const symptomTimeFrameAnswers = [
    TIME_FRAME_PER_HOUR,
    TIME_FRAME_PER_DAY,
    TIME_FRAME_PER_WEEK,
    TIME_FRAME_PER_MONTH,
    TIME_FRAME_PER_YEAR,
];

export const timeFrameMap = {
    [DURATION_TIME_FRAME_HOURS.id]: TIME_FRAME_PER_HOUR.id,
    [DURATION_TIME_FRAME_DAYS.id]: TIME_FRAME_PER_DAY.id,
    [DURATION_TIME_FRAME_WEEKS.id]: TIME_FRAME_PER_WEEK.id,
    [DURATION_TIME_FRAME_MONTHS.id]: TIME_FRAME_PER_MONTH.id,
    [DURATION_TIME_FRAME_YEARS.id]: TIME_FRAME_PER_YEAR.id,
    [TIME_FRAME_PER_HOUR.id]: DURATION_TIME_FRAME_HOURS.id,
    [TIME_FRAME_PER_DAY.id]: DURATION_TIME_FRAME_DAYS.id,
    [TIME_FRAME_PER_WEEK.id]: DURATION_TIME_FRAME_WEEKS.id,
    [TIME_FRAME_PER_MONTH.id]: DURATION_TIME_FRAME_MONTHS.id,
    [TIME_FRAME_PER_YEAR.id]: DURATION_TIME_FRAME_YEARS.id,
};

export const CONTROL_LEVEL_WELL_CONTROLLED = {
    id: 'WC',
    message: messages.wellControlled,
};

export const CONTROL_LEVEL_REASONABLY_WELL_CONTROLLED = {
    id: 'RWC',
    message: messages.reasonablyWellControlled,
};

export const CONTROL_LEVEL_NOT_SO_WELL_CONTROLLED = {
    id: 'NSWC',
    message: messages.notSoWellControlled,
};

export const CONTROL_LEVEL_POORLY_CONTROLLED = {
    id: 'PC',
    message: messages.poorlyControlled,
};

export const pastConditionControlLevelAnswers = [
    CONTROL_LEVEL_WELL_CONTROLLED,
    CONTROL_LEVEL_REASONABLY_WELL_CONTROLLED,
    CONTROL_LEVEL_NOT_SO_WELL_CONTROLLED,
    CONTROL_LEVEL_POORLY_CONTROLLED,
];

export const RECENT_CHANGE_BETTER = {
    id: 'RCLF',
    message: messages.recentChangeBetter,
};

export const RECENT_CHANGE_WORSE = {
    id: 'RCMF',
    message: messages.recentChangeWorse,
};

export const RECENT_CHANGE_SAME = {
    id: 'RCS',
    message: messages.recentChangeSame,
};

export const recentChangeAnswers = [
    RECENT_CHANGE_BETTER,
    RECENT_CHANGE_WORSE,
    RECENT_CHANGE_SAME,
];

export const recentChangeTimeFrameAnswers = [
    DURATION_TIME_FRAME_DAYS,
    DURATION_TIME_FRAME_WEEKS,
    DURATION_TIME_FRAME_MONTHS,
    DURATION_TIME_FRAME_YEARS,
];

export const EMPLOYMENT_STATUS_THRESHOLD = 5;

export const DEMOGRAPHICS_HABIT_THRESHOLD = 13;

export const PROBLEM_TYPE_CONDITION = 'condition';
export const PROBLEM_TYPE_FREE_TEXT = 'freeTextSymptom';
export const PROBLEM_TYPE_SYMPTOM = 'symptom';
export const PROBLEM_TYPE_PRESCRIPTION = 'prescription';
export const PROBLEM_TYPE_PRESCRIPTION_RFV = 'prescription-rfv';

export function translateAnswers(answers, intl) {
    return answers.map((answer) => ({
        ...answer,
        text: intl.formatMessage(answer.message),
    }));
}

export const WALK_IN_EXISTING_PATIENT = {
    id: 'WIEP',
    message: messages.walkInExistingPatient,
};

export const WALK_IN_NEW_PATIENT = {
    id: 'WINP',
    message: messages.walkInNewPatient,
};

export const walkInPatientFlowAnswers = [
    WALK_IN_EXISTING_PATIENT,
    WALK_IN_NEW_PATIENT,
];
