import http from 'http.js';

class AutocompleteManager {
    constructor() {
        this.currentRequest = null;
    }

    async getResults(url, data = {}, method = 'post') {
        if (this.currentRequest) {
            this.currentRequest.abort();
        }
        if (method === 'post') {
            this.currentRequest = http.post({url, data});
        } else {
            this.currentRequest = http.get({url, data});
        }
        const resp = await this.currentRequest;
        if (resp !== null) {
            this.currentRequest = null;
        }
        return resp;
    }
}

export default new AutocompleteManager();
