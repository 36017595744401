import deduplicate from 'utils/deduplicate.js';

export function getPatchData({value, toReplace, toAdd, toRemove}) {
    let newValue;
    if (toReplace === undefined) {
        newValue = value || [];
        newValue = newValue.map((item) => item.id || item);
        if (toRemove) {
            newValue = newValue.filter((item) => !toRemove.includes(item));
        }
        if (toAdd) {
            newValue = newValue.concat(toAdd);
            newValue = deduplicate(newValue);
        }
    } else {
        newValue = toReplace;
    }
    return newValue;
}
