import actions from 'better_consult/actions/types.js';
import {CORE_LANGUAGES_API} from 'apiEndpoints';
import {getStepAndProblemData} from 'consultations/actions/consultationActions.js';
import http from 'http.js';
import {captureException, captureMessage} from 'sentry.js';

export function showModal(type, props = {}) {
    return {
        type: actions.app.SHOW_MODAL,
        payload: {type, props},
    };
}

export function handleError({error, message, isUndo = false}) {
    return function (dispatch) {
        const info = dispatch(getStepAndProblemData(isUndo));
        if (message) {
            info.feedback = message.trim();
            const errorMessage = 'User generated feedback from error';
            const tags = {
                feature: 'feedback',
            };
            captureMessage({errorMessage, info, tags});
        } else {
            captureException({error, info});
        }
    };
}

export function hideModal() {
    return {type: actions.app.HIDE_MODAL};
}

export function showNotification(notificationDetails) {
    return {
        type: actions.app.SHOW_NOTIFICATION,
        payload: {notificationDetails},
    };
}

export function hideNotification() {
    return {type: actions.app.HIDE_NOTIFICATION};
}

export async function getSupportedLanguages() {
    let response;
    try {
        response = await http.get({
            url: CORE_LANGUAGES_API,
        });
    } catch (err) {
        return [];
    }
    return response;
}

export function setClientType(clientType) {
    return async function (dispatch) {
        dispatch({
            type: actions.app.SET_CLIENT_TYPE,
            payload: clientType,
        });
    };
}

export const notificationActions = {
    reload() {
        return function () {
            location.reload();
        };
    },
    report() {
        return function (dispatch) {
            dispatch(hideNotification());
            dispatch(showModal('FEEDBACK_MODAL', {error: true}));
        };
    },
};
