/* eslint-disable max-len */
const app = {
    SHOW_MODAL: 'SHOW_MODAL',
    HIDE_MODAL: 'HIDE_MODAL',
    SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
    HIDE_NOTIFICATION: 'HIDE_NOTIFICATION',
    SET_CLIENT_TYPE: 'SET_CLIENT_TYPE',
    SET_MUST_COMPLY_WITH_HIPAA: 'SET_MUST_COMPLY_WITH_HIPAA',
    SET_FEATURE_FLAGS: 'SET_FEATURE_FLAGS',
    SET_SID: 'SET_SID',
};

const accounts = {
    GET_CONSULTATIONS_SUCCESS: 'GET_CONSULTATIONS_SUCCESS',
    GET_CONSULTATIONS_FAILED: 'GET_CONSULTATIONS_FAILED',
    GET_HEALTH_FUNDS_SUCCESS: 'GET_HEALTH_FUNDS_SUCCESS',
    GET_HEALTH_FUNDS_FAILED: 'GET_HEALTH_FUNDS_FAILED',
    CREATE_PATIENT_SUCCESS: 'CREATE_PATIENT_SUCCESS',
    SELECT_PRACTICE_SUCCESS: 'SELECT_PRACTICE_SUCCESS',
    SEND_RESET_PASSWORD_EMAIL_FAILED: 'SEND_RESET_PASSWORD_EMAIL_FAILED',
    SEND_RESET_PASSWORD_EMAIL_SUCCESS: 'SEND_RESET_PASSWORD_EMAIL_SUCCESS',
    SET_PATIENT_SUCCESS: 'SET_PATIENT_SUCCESS',
    SET_PATIENT_FAILED: 'SET_PATIENT_FAILED',
    UNDO_UPDATE_PATIENT_SUCCESS: 'UNDO_UPDATE_PATIENT_SUCCESS',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILED: 'GET_USER_FAILED',
    REMOVE_PATIENT_SUCCESS: 'REMOVE_PATIENT_SUCCESS',
    LOGOUT_USER_SUCCESS: 'LOGOUT_USER_SUCCESS',
    SET_LANGUAGE_SUCCESS: 'SET_LANGUAGES_SUCCESS',
    SET_PASSWORD_FAILED: 'SET_PASSWORD_FAILED',
    SET_PASSWORD_SUCCESS: 'SET_PASSWORD_SUCCESS',
    SET_TERMS_OF_USE: 'SET_TERMS_OF_USE',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILED: 'UPDATE_USER_FAILED',
};

const consultations = {
    NEXT_STEP: 'NEXT_STEP',
    GO_TO_STEP_KEY: 'GO_TO_STEP_KEY',
    RESTORE_CONSULTATION_SUCCESS: 'RESTORE_CONSULTATION_SUCCESS',
    INSERT_STEPS: 'INSERT_STEPS',
    SAVE_CONSULTATION_STORE_SUCCESS: 'SAVE_CONSULTATION_STORE_SUCCESS',
    SAVE_CONSULTATION_STORE_FAILED: 'SAVE_CONSULTATION_STORE_FAILED',
    SUBMIT_BODY_PART_SUCCESS: 'SUBMIT_BODY_PART_SUCCESS',
    SUBMIT_BODY_PART_FAILED: 'SUBMIT_BODY_PART_FAILED',
    UNDO_SUBMIT_BODY_PART_SUCCESS: 'UNDO_SUBMIT_BODY_PART_SUCCESS',
    UNDO_SUBMIT_BODY_PART_FAILED: 'UNDO_SUBMIT_BODY_PART_FAILED',
    SUBMIT_BODY_PART_PIN_POINT_SUCCESS: 'SUBMIT_BODY_PART_PIN_POINT_SUCCESS',
    SUBMIT_BODY_PART_PIN_POINT_FAILED: 'SUBMIT_BODY_PART_PIN_POINT_FAILED',
    UNDO_SUBMIT_BODY_PART_PIN_POINT_SUCCESS:
        'UNDO_SUBMIT_BODY_PART_PIN_POINT_SUCCESS',
    UNDO_SUBMIT_BODY_PART_PIN_POINT_FAILED:
        'UNDO_SUBMIT_BODY_PART_PIN_POINT_FAILED',
    REMOVE_STEP: '_OLD_REMOVE_STEP',
};

const dashboard = {
    CLEAR_DATA: 'CLEAR_DATA',
    SET_DASHBOARD_ERROR: 'SET_DASHBOARD_ERROR',
    SET_DATA: 'SET_DATA',
    SET_SELECTED_ATTR: 'SET_SELECTED_ATTR',
};

const data = {
    ADD_CLINICAL_SYMPTOM: 'ADD_CLINICAL_SYMPTOM',
    REMOVE_CLINICAL_SYMPTOM: 'REMOVE_CLINICAL_SYMPTOM',
    ADD_CLINICAL_CONDITION: 'ADD_CLINICAL_CONDITION',
    REMOVE_CLINICAL_CONDITION: 'REMOVE_CLINICAL_CONDITION',
    ADD_CLINICAL_MEDICATION: 'ADD_CLINICAL_MEDICATION',
    REMOVE_CLINICAL_MEDICATION: 'REMOVE_CLINICAL_MEDICATION',
    ADD_CONSTITUTIONAL_SYMPTOMS: 'ADD_CONSTITUTIONAL_SYMPTOMS',
    ADD_MEDICATION_DOSAGE: 'ADD_MEDICATION_DOSAGE',
    ADD_RECREATIONAL_DRUGS: 'ADD_RECREATIONAL_DRUGS',
    SET_CONSULTATION_ATTR: 'SET_CONSULTATION_ATTR',
    CONSULTATION_CHECK_TOKEN_SUCCESS: 'CONSULTATION_CHECK_TOKEN_SUCCESS',
    CONSULTATION_CHECK_TOKEN_FAILED: 'CONSULTATION_CHECK_TOKEN_FAILED',
    ADD_CONSULTATION_SYMPTOM: 'ADD_CONSULTATION_SYMPTOM',
    REMOVE_CONSULTATION_SYMPTOM: 'REMOVE_CONSULTATION_SYMPTOM',
    SET_CONSULTATION_SYMPTOM_ATTR: 'SET_CONSULTATION_SYMPTOM_ATTR',
    REMOVE_RELATED_SYMPTOM_FROM_PRESENTING_SYMPTOM:
        'REMOVE_RELATED_SYMPTOM_FROM_PRESENTING_SYMPTOM',
    ADD_MEDICATION_COURSE: 'ADD_MEDICATION_COURSE',
    REMOVE_MEDICATION_COURSE: 'REMOVE_MEDICATION_COURSE',
    SET_MEDICATION_COURSE_ATTR: 'SET_MEDICATION_COURSE_ATTR',
    ADD_PATIENT: 'ADD_PATIENT',
    REMOVE_PATIENT: 'REMOVE_PATIENT',
    SET_PATIENT_ATTR: 'SET_PATIENT_ATTR',
    ADD_PAST_CONDITIONS: 'ADD_PAST_CONDITIONS',
    REMOVE_PAST_CONDITIONS: 'REMOVE_PAST_CONDITIONS',
    SET_PAST_CONDITION_ATTR: 'SET_PAST_CONDITION_ATTR',
    ADD_FAMILY_CONDITIONS: 'ADD_FAMILY_CONDITIONS',
    REMOVE_FAMILY_CONDITIONS: 'REMOVE_FAMILY_CONDITIONS',
    SET_FAMILY_CONDITION_ATTR: 'SET_FAMILY_CONDITION_ATTR',
    ADD_PATIENT_RECREATIONAL_DRUGS: 'ADD_PATIENT_RECREATIONAL_DRUGS',
    SET_PATIENT_RECREATIONAL_DRUG_ATTR: 'SET_PATIENT_RECREATIONAL_DRUG_ATTR',
    SUBMIT_SYMPTOM_QUESTION_SUCCESS: 'SUBMIT_SYMPTOM_QUESTION_SUCCESS',
    UNDO_SUBMIT_SYMPTOM_QUESTION_SUCCESS:
        'UNDO_SUBMIT_SYMPTOM_QUESTION_SUCCESS',
    SUBMIT_CONDITION_QUESTION_SUCCESS: 'SUBMIT_CONDITION_QUESTION_SUCCESS',
    UNDO_SUBMIT_CONDITION_QUESTION_SUCCESS:
        'UNDO_SUBMIT_CONDITION_QUESTION_SUCCESS',
    ADD_FEEDBACK_QUESTIONS: 'ADD_FEEDBACK_QUESTIONS',
    CLEAR_FEEDBACK_QUESTIONS: 'CLEAR_FEEDBACK_QUESTIONS',
    SUBMIT_FEEDBACK_SUCCESS: 'SUBMIT_FEEDBACK_SUCCESS',
    UNDO_SUBMIT_FEEDBACK_SUCCESS: 'UNDO_SUBMIT_FEEDBACK_SUCCESS',
    ADD_REVIEW_OF_SYSTEMS: 'ADD_REVIEW_OF_SYSTEMS',
    REMOVE_REVIEW_OF_SYSTEMS: 'REMOVE_REVIEW_OF_SYSTEMS',
    SUBMIT_MEDICATION_QUESTION_SUCCESS: 'SUBMIT_MEDICATION_QUESTION_SUCCESS',
    UNDO_SUBMIT_MEDICATION_QUESTION_SUCCESS:
        'UNDO_SUBMIT_MEDICATION_QUESTION_SUCCESS',
    ADD_RELATED_SYMPTOM: 'ADD_RELATED_SYMPTOM',
    REMOVE_RELATED_SYMPTOM: 'REMOVE_RELATED_SYMPTOM',
};

const form = {
    ADD_STEPS: 'ADD_STEPS',
    REMOVE_CHILDREN_STEPS: 'REMOVE_CHILDREN_STEPS',
    REMOVE_STEP: 'REMOVE_STEP',
    SET_FORM_RELOADING: 'SET_FORM_RELOADING',
    SET_STEP_ATTR: 'SET_STEP_ATTR',
    UPDATE_PROGRESS: 'UPDATE_PROGRESS',
    POP_ADDEND: 'POP_ADDEND',
    PUSH_ADDEND: 'PUSH_ADDEND',
    POP_INCREMENT: 'POP_INCREMENT',
    PUSH_INCREMENT: 'PUSH_INCREMENT',
    RELOAD_INCREMENTS: 'RELOAD_INCREMENTS',
    FINAL_STEP: 'FINAL_STEP',
};

const questions = {
    GET_GLOBAL_QUESTIONS_SUCCESS: 'GET_GLOBAL_QUESTIONS_SUCCESS',
    SUBMIT_GLOBAL_ANSWERS_SUCCESS: 'SUBMIT_GLOBAL_ANSWERS_SUCCESS',
    UNDO_SUBMIT_GLOBAL_ANSWERS_SUCCESS: 'UNDO_SUBMIT_GLOBAL_ANSWERS_SUCCESS',
};

export default {
    app,
    accounts,
    consultations,
    dashboard,
    data,
    form,
    questions,
};
