import React from 'react';
import {withRouter} from 'react-router';
import preserveQueryString from 'utils/preserve_query_string.js';

let history;

export class History extends React.Component {
    componentDidMount() {
        history = this.props.history;
    }

    componentDidUpdate(prevProps) {
        if (this.props.history !== prevProps.history) {
            history = this.props.history;
        }
    }

    render() {
        return null;
    }
}

export default withRouter(History);

export function getHistory() {
    return history;
}

export function pushHistory(route, options = {preservedQueryKeys: []}) {
    if (history.location.pathname !== route) {
        history.push(preserveQueryString(route, options.preservedQueryKeys));
    }
}
