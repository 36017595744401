import autobind from 'common/decorators/autobind.js';
import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, injectIntl} from 'react-intl';

import {BETTER_CONSULT_FLAT_PAGES} from 'apiEndpoints';
import http from 'http.js';

import Icon from 'utils/components/Icon.js';

const messages = defineMessages({
    buttonText: {
        id: 'accounts.FlatPageModal.buttonText',
        defaultMessage: 'buttonText',
    },
    buttonTitle: {
        id: 'accounts.FlatPageModal.buttonTitle',
        defaultMessage: 'buttonTitle',
    },
});

export class FlatPageModal extends React.Component {
    static propTypes = {
        closeFunction: PropTypes.func.isRequired,
        urlPath: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            content: null,
        };
        this.loadContent();
    }

    @autobind
    loadContent() {
        const url = `${BETTER_CONSULT_FLAT_PAGES}${this.props.urlPath}`;
        http.get({url}).then((response) => {
            this.setState({
                content: response.content,
            });
        });
    }

    render() {
        if (this.state.content === null) {
            return <div aria-busy="true" className="loader" />;
        }
        const intl = this.props.intl;
        return (
            <React.Fragment>
                <button
                    autoFocus={true}
                    className="dismiss"
                    data-test-id="flat-page-modal-dismiss-button"
                    onClick={this.props.closeFunction}
                    title={intl.formatMessage(messages.buttonTitle)}
                    type="button"
                >
                    <Icon name="IconReset" />
                </button>
                <div className="dialog-body">
                    <article
                        data-test-id="flat-page-modal-content"
                        dangerouslySetInnerHTML={{__html: this.state.content}}
                    />
                    <footer>
                        <button
                            className="cta"
                            data-test-id="flat-page-modal-close-button"
                            onClick={this.props.closeFunction}
                            type="button"
                        >
                            {intl.formatMessage(messages.buttonText)}
                        </button>
                    </footer>
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(FlatPageModal);
