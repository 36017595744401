/* eslint max-len: 0 */
export const ACTIVE_CONSULTATION_API =
    '/api/consultations/v1/active-consultation/';
export const CONSULTATIONS_CHECK_API = '/api/consultations/v1/check/';
export const CONSULTATIONS_CLAIM_API = '/api/consultations/v1/claim/';
export const CONSULTATIONS_CONSULTATIONS_API =
    '/api/consultations/v1/consultations/';
export const CONSULTATIONS_CONSULTATIONS_API_V2 =
    '/api/consultations/v2/consultations/';
export const CONSULTATIONS_FEEDBACK_API =
    '/api/consultations/v1/consultations/feedback/';
export const CONSULTATIONS_REASONS_API = '/api/consultations/v1/reasons/';
export const CONSULTATIONS_SEARCH_API = '/api/consultations/v1/search';
export const WORKFLOWS_API = '/api/workflows/v1';

export const ACCOUNTS_HEALTHFUNDS_API = '/api/accounts/v1/health-funds/';
export const ACCOUNTS_LANGUAGE_API = '/api/accounts/v1/language/';
export const ACCOUNTS_LOGIN_API = '/api/accounts/v1/login/';
export const ACCOUNTS_LOGIN_GUEST_API = '/api/accounts/v1/login/guest/';
export const ACCOUNTS_LOGOUT_API = '/api/accounts/v1/logout/';
export const ACCOUNTS_PATIENTS_API = '/api/accounts/v1/patients/';
export const ACCOUNTS_PRACTICE_USER_API = '/api/accounts/v1/practice-users/';
export const ACCOUNTS_PRACTICES_API = '/api/accounts/v1/practices/';
export const ACCOUNTS_PRACTITIONERS_API = '/api/accounts/v1/practitioners/';
export const ACCOUNTS_RENEW_SESSION_API = '/api/accounts/v1/renew-session/';
export const ACCOUNTS_SET_PASSWORD_API = '/api/accounts/v1/set-password/';
export const ACCOUNTS_USER_INFO_API = '/api/accounts/v1/user-info/';
export const ACCOUNTS_USER_STORE_API = '/api/accounts/v1/user/store/';
export const ACCOUNTS_RESEND_EMAIL_CONFIRMATION =
    '/api/accounts/v1/resend-email-confirmation/';
export const ACCOUNTS_RESET_PASSWORD = '/api/accounts/v1/reset-password/';
export const ACCOUNTS_FEEDBACK_API = '/api/accounts/v1/feedback/';
export const ACCOUNTS_PMS_BOOKING_TYPES_API =
    '/api/accounts/v1/pms/booking-types/';

export const BETTER_CONSULT_AUTOCOMPLETE =
    '/api/better-consult/v1/autocomplete/';
export const BETTER_CONSULT_FLAT_PAGES = '/api/better-consult/v1/flat-pages/';

export const BREATHE_API = '/api/breathe/v1/';

export const CORE_LANGUAGES_API = '/api/core/v1/languages/';
export const CORE_MESSAGES_API = '/api/core/v1/messages/';

export const CLINICAL_CONDITIONS_SEARCH_API =
    '/api/clinical/v1/conditions/search/';
export const CLINICAL_DEMOGRAPHIC_FILTERS_VALUES_API =
    '/api/clinical/v1/demographic-filters/values/';
export const CLINICAL_RECREATIONAL_DRUGS_API =
    '/api/clinical/v1/recreational-drugs/';
export const CLINICAL_SYMPTOMS_API = '/api/clinical/v1/symptoms/';
export const CLINICAL_PROBLEMS_SEARCH_API =
    '/api/clinical/v1/problems/search/';
export const CLINICAL_REVIEW_OF_SYSTEMS_API = '/api/clinical/v1/body-systems/';

export const DASHBOARD_FEEDBACK_API = '/api/dashboard/v1/feedback/';

export const MEDICATIONS_SEARCH_API = '/api/treatments/v1/medications/search/';

export const QUESTIONS_API = '/api/questions/v1/';

export const TREATMENTS_API = '/api/treatments/v1/';

export const FEATURE_FLAGS_API = '/api/core/v1/feature-flags/';

export const PRACTITIONER_FEEDBACK_API = '/feedback/practitioner-feedback/';

export const SUPPORT_CONSULTATION_INFO_API =
    '/api/support/v1/consultation-info/';
export const SUPPORT_AUDIT_TRAIL_API = '/api/support/v1/support-audit-trail/';
export const SUPPORT_PRACTICE_SEARCH_API = '/api/support/v1/practice/search/';
export const SUPPORT_PRACTICE_USER_API = '/api/support/v1/practice/user/';

export const WORKFLOWS_FEEDBACK_API = '/api/workflows/v1/workflows/feedback/';
